<!--
 * @Description: 开票信息管理
 * @Author: ChenXueLin
 * @Date: 2021-10-09 10:00:00
 * @LastEditTime: 2022-07-22 17:52:52
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="billingCorpName">
              <el-input
                v-model.trim="searchForm.billingCorpName"
                placeholder="开票名称"
                title="开票名称"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="customerName">
              <el-input
                v-model.trim="searchForm.customerName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="invoicePeopleType">
              <e6-vr-select
                v-model="searchForm.invoicePeopleType"
                :data="invoicePeopleTypeEnum"
                placeholder="纳税人类别"
                title="纳税人类别"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="invoiceType">
              <e6-vr-select
                v-model="searchForm.invoiceType"
                :data="invoiceTypeEnum"
                placeholder="开票类型"
                title="开票类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taxNo">
              <el-input
                v-model.trim="searchForm.taxNo"
                placeholder="纳税人识别编号"
                title="纳税人识别编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="bankName">
              <el-input
                v-model.trim="searchForm.bankName"
                placeholder="开户行名称"
                title="开户行名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="bankNumber">
              <el-input
                v-model.trim="searchForm.bankNumber"
                placeholder="开户行账号"
                title="开户行账号"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="address">
              <el-input
                v-model.trim="searchForm.address"
                placeholder="开户行地址"
                title="开户行地址"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-add_line" title="新增" @click="handleOperate()"></i>
          <!-- <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i> -->
          <i class="e6-icon-export_line" title="导出" @click="exporData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          ref="tableList"
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'invoiceType'">
                {{ getInvoiceTypeName(row.invoiceType) }}
              </span>
              <span v-else-if="column.fieldName === 'invoicePeopleType'">
                {{ getInvoicePeopleTypeName(row.invoicePeopleType) }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import {
  getInvoiceData,
  deleteInvoiceData,
  findDownList,
  exportInvoiceData
} from "@/api";
export default {
  name: "invoiceManage",
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      searchForm: {
        billingCorpName: "", //开票名称
        customerName: "", //客户名称
        invoicePeopleType: "", //纳税人类别
        invoiceType: "", //开票类型
        taxNo: "", //纳税人识别编号
        address: "", //开户行地址
        bankName: "", //公司开户行名称
        bankNumber: "", //开户行账号
        pageIndex: 1,
        pageSize: 20
      },
      queryListAPI: getInvoiceData,

      invoicePeopleTypeEnum: [], // 纳税人类别
      invoiceTypeEnum: [], // 开票类型
      total: 0,
      selColumnId: [], // 多选数据（ids）
      columnData: [
        {
          fieldName: "billingCorpId",
          display: true,
          fieldLabel: "开票ID",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "crmId",
          display: true,
          fieldLabel: "crmId",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "billingCorpName",
          display: true,
          fieldLabel: "开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "customerName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "allowToMakeInvoiceName",
          display: true,
          fieldLabel: "是否允许开票",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoicePeopleType",
          display: true,
          fieldLabel: "纳税人类别",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceType",
          display: true,
          fieldLabel: "开票类型",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taxNo",
          display: true,
          fieldLabel: "纳税人识别编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bankName",
          display: true,
          fieldLabel: "开户行名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bankNumber",
          display: true,
          fieldLabel: "开户行账号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "address",
          display: true,
          fieldLabel: "开户行地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedBy",
          display: true,
          fieldLabel: "修改人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  computed: {},
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        //获取枚举数据
        let promiseList = [findDownList(["invoicePeopleType", "invoiceType"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.invoicePeopleTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.invoicePeopleType"
        });
        this.invoiceTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.invoiceType"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.billingCorpId;
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所选开票信息?", "删除开票信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteCorp();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //删除客户请求
    async deleteCorp() {
      try {
        this.loading = true;
        let res = await deleteInvoiceData({
          ids: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleOperate(val, row) {
      this.routerPush({
        name: "customerManage/invoiceManageAdd",
        params: {
          refresh: true,
          id: row ? row.billingCorpId : "",
          type: row ? "edit" : "add",
          origin: "customerManage/invoiceManage"
        }
      });
    },
    handleAddOrUpdate() {
      this.handleOperate();
    },
    // 导出
    exporData() {
      exportData(this, exportInvoiceData);
    },
    // 获取开票类型
    getInvoiceTypeName(val) {
      const target = this.invoiceTypeEnum.find(item => item.codeValue == val);
      return target ? target.codeName : val;
    },
    // 获取开票类型
    getInvoicePeopleTypeName(val) {
      const target = this.invoicePeopleTypeEnum.find(item => {
        return item.codeValue == val;
      });
      return target ? target.codeName : val;
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "invoiceRecord",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
